import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ isHome, title, children }) => {
  let header

  if (isHome) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper">
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        &copy; {new Date().getFullYear()} All rights reserved.
      </footer>
    </div>
  )
}

export default Layout
